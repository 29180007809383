import { ImageSource, PhotoPrice } from '../reducers/gallery/model';

const sumPriceInSource = (source: ImageSource[] | number, photoPrices: PhotoPrice[]): string => {
  // console.log(`Photo prices: ${JSON.stringify(photoPrices)}`);

  if (typeof source === 'number') {
    return (Math.round(source * 100) / 100).toFixed(2);
  }
  let num = 0;
  let index = 1;
  for (const a of source) {
    const price = a.price ? a.price : 0;
    let photoPricesAtIndex = photoPrices.filter(price => price.amount === index);
    if (photoPricesAtIndex.length > 0) {
      num = photoPricesAtIndex[0].price;
    } else {
      num += price;
    }
    index++;
  }
  return (Math.round(num * 100) / 100).toFixed(2);
};

const validateDOB = (dobString: string): boolean => {
  let dobDate = new Date(dobString);
  let currentDate = new Date();
  if (currentDate.getFullYear() - dobDate.getFullYear() < 18) {
    return false;
  }

  if (currentDate.getFullYear() - dobDate.getFullYear() == 18) {
    //11/06/2018 and DOB: 15/07/2000. Will turned 18 on 15/07/2018.
    if (currentDate.getMonth() < dobDate.getMonth()) {
      return false;
    }
    if (currentDate.getMonth() == dobDate.getMonth()) {
      //11/06/2018 and DOB: 15/06/2000. Will turned 18 on 15/06/2018.
      if (currentDate.getDate() < dobDate.getDate()) {
        return false;
      }
    }
  }
  return true;
}

export { sumPriceInSource, validateDOB };
