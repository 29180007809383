import React, { FunctionComponent } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import { CardElementStyle } from './CardElementStyle';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'RubikRegular, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '15px',
      '::placeholder': {
        color: '#898A8F',
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
  hidePostalCode: true
};

interface CardSectionProps {
  onChange?: (e: any) => void;
}

const CardSection: FunctionComponent<CardSectionProps> = props => {
  const { onChange } = props;

  return (
    <CardElementStyle>
      {
        typeof window !== 'undefined' ?
          <CardElement options={CARD_ELEMENT_OPTIONS} onChange={onChange} />
          : <span>Stripe isn't supported</span>
      }
    </CardElementStyle >
  );
};

export default CardSection;
