import styled from '@emotion/styled';

const CheckoutPageStyle = styled.div`
  background: rgb(248, 248, 248);
  background: linear-gradient(0deg, rgba(248, 248, 248, 1) 0%, rgba(255, 255, 255, 1) 80%, rgba(249, 249, 249, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 775px;
  .btn {
      width: auto;
      border-radius: 4px;
    }
  }

  .btn--remove {
    background-color: #fbca00 !important;
  }
  .form--control {
    width: 352px;
    border: 1px solid #cfd1d6;
    border-radius: 4px;
    background-color: #f7f7f7;
  }

  .main__container {
    display: flex;
    flex-direction: row;
    .header__form {
      display: flex;
      align-items: center;
      width: 100%;
      align-items: center;
      border-bottom: 1px solid #cfd1d6;
      background-color: #ffffff;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      p {
        font-family: RubikMedium;
        margin: 14px 27px;
      }
      img {
        width: auto;
        height: auto;
        transform: scale(0.6);
      }
    }

    .body__form {
      padding: 29px 33px;
      .btn--control {
        padding: 12px 0;
        justify-content: center;
        span {
          font-size: 15px;
          line-height: 18px;
          font-family: RubikMedium;
        }
      }
      .btn--order {
        border-radius: 23px;
      }
    }

    .form--coupon {
      .body__form {
        .group__input {
          display: flex;
          .btn--control {
            margin: 0 0 0 11px;
            background-color: #b6b7bb;
            max-width: 97px;
            width: 100%;
          }

          .input-control {
            width: 284px;
            height: 46px;
            position: relative;

            .input--control {
              position: absolute;
              top: 0;
              right: 0;
              width: 100%;
              height: 100%;

              input {
                text-transform: uppercase;
              }

              input::placeholder {
                text-transform: none;
              }
            }

            .btn-remove {
              position: absolute;
              top: -48%;
              right: -6%;
              z-index: 10;
              background: none;
              color: #1d2332;
              font-family: RubikMedium;
              font-size: 14px;
            }
          }
        }
        .group__text {
          display: flex;
          margin: 29px 0 14px 0;
          .left__side,
          .right__side {
            display: flex;
            flex-direction: column;
            flex: 5;
            p {
              margin: 6.5px 0 0 0;
            }
          }
          .left__side {
            align-items: flex-start;
            p {
              font-family: RubikMedium;
              font-size: 12px;
              line-height: 24px;
              color: #8e9198;
            }
          }
          .right__side {
            align-items: flex-end;
            p {
              font-size: 15px;
              line-height: 24px;
              color: #1d2332;
            }
            p.text--coupon {
              color: #28b893;
            }
            p.text--amount {
              font-family: RuBikMedium;
              font-size: 22px;
            }
          }
        }
      }
    }

    .form--payment {
      margin-left: 33px;
      .body__form {
        .group__input {
          .input--control {
            &.margin {
              margin-top: 13px;
            }
            &.input--card {
              position: relative;
              padding-left: 20px;
              padding-right: 20px;
              .icon--control {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 5%;
                fill: #ccc;
              }
              .date--control {
                position: absolute;
                right: 5%;
                top: 50%;
                transform: translateY(-50%);
                color: #898a8f;
                font-size: 15px;
                line-height: 24px;
              }
            }
          }
        }
        .btn--control {
          width: 100%;
          justify-content: center;
          margin: 14px 0 0 0;
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .main__container {
      flex-direction: column;
      align-items: center;
      .form--payment {
        margin: 33px 0 0 0;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .main__container {
      .form--control {
        width: 310px;
        .body__form {
          .input--card {
            input {
              padding-left: 15px;
              padding-right: 50px;
            }
          }
        }
      }
      .form--payment {
        .header__form {
          p {
            margin: 14px 7px;
          }
        }
      }
    }
  }
`;

export { CheckoutPageStyle };
