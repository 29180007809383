import React, { FunctionComponent, useState, useEffect } from 'react';
import { CheckoutPageStyle } from './CheckoutPageStyle';
import Layout from '../../layouts/Layout';
import InputCheckout from '../../components/Form/InputCheckout';
import Button from '../../components/Form/Button';
import { css } from '@emotion/core';
import { connect } from 'react-redux';
import { RootState } from '../../reducers';
import { getCoupon, saveCoupon } from '../../reducers/gallery/action';
import { ImageSource, Coupon, PhotoPrice } from '../../reducers/gallery/model';
import { sumPriceInSource } from '../../utils/math';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import CardSection from '../../components/Form/CardSection';
import ConfirmModal from '../../components/ConfirmModal';
import { navigate } from '@reach/router';
import { FadeLoader } from 'react-spinners';
import { getPaymentIntentClientSecret, checkCoupon } from '../../api/index';
import { removeImageFromCart } from '../../reducers/gallery/action';
import { Helmet } from 'react-helmet';

interface CheckOutPageProps {
  cartList: ImageSource[];
  coupon: Coupon;
  getCouponAction: () => void;
  saveCouponAction: (coupon?: Coupon) => void;
  removeImageFromCartAction: (id: string) => void;
  photoPrices: PhotoPrice[];
}

interface ConfirmModal {
  className?: string;
  header?: string;
  body?: string;
  buttonText?: string;
  showing?: boolean;
  callback?: () => void;
}

const CheckoutPage: FunctionComponent<CheckOutPageProps> = props => {
  const { coupon, saveCouponAction, cartList, removeImageFromCartAction, photoPrices } = props;

  const [isApplyCoupon, setApplyCoupon] = useState(false);
  const [couponCode, setCoupon] = useState(coupon ? coupon : null);
  const [cardHolderName, setCardHolderName] = useState('');
  const [email, setEmail] = useState('');
  const [cardComplete, setCardComplete] = useState(false);

  const [state, setState] = useState({
    isLoading: false
  });

  const onApplyCoupon = () => {
    const data = checkCoupon(couponCode!.code);
    setState({ isLoading: true });

    data.then(
      response => {
        setState({ isLoading: false });

        if (response.data.success) {
          saveCouponAction(response.data.data);
        } else {
          setConfirmation({
            header: 'Coupon',
            body: response.data.message,
            buttonText: 'OK',
            showing: true,
            callback: () => setConfirmation({ ...state, showing: false })
          });
        }
      },
      error => {
        setState({ isLoading: false });
        setConfirmation({
          header: 'Coupon',
          body: error.stack,
          buttonText: 'OK',
          showing: true,
          callback: () => setConfirmation({ ...state, showing: false })
        });
      }
    )
  };

  const onRemoveCoupon = () => {
    setApplyCoupon(!isApplyCoupon);
    setCoupon(null);
    saveCouponAction();
  }

  const onChangeCoupon = (value: string) => {
    setCoupon({ code: value, discountAmount: 0, _id: '' });
  }

  const onChangeCardHoldername = (value: string) => {
    setCardHolderName(value);
  }

  const onChangeEmail = (value: string) => {
    setEmail(value);
  }

  const onChangeCard = (value: any) => {
    setCardComplete(value.complete);
  }

  const goToGalleryPage = () => {
    window.location.href = '/gallery';
  }

  const [confirmation, setConfirmation] = useState<ConfirmModal>({
    header: 'Order complete!',
    body: 'Your new TravelPix are now available to download as you like!',
    buttonText: 'Take a look',
    showing: false,
    callback: goToGalleryPage
  });

  useEffect(() => {
    // getCouponAction();
    setCoupon(coupon);
    setApplyCoupon(coupon ? true : false);

    if (cartList.length === 0) {
      if (typeof window !== 'undefined') {
        navigate('/gallery');
      }
    }
  }, [coupon]);

  var handleSubmit = async (event: any) => {
    event.preventDefault();
  }

  if (typeof window !== 'undefined') {
    const stripe = useStripe();
    const elements = useElements();

    handleSubmit = async (event: any) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      if (cardHolderName.trim() === '' || email.trim() === '' || !cardComplete) {
        setConfirmation({
          header: 'Payment',
          body: 'Please fill in all card holder name, email and card info fields!',
          buttonText: 'OK',
          showing: true,
          callback: () => setConfirmation({ ...state, showing: false })
        });
        return;
      }
      setState({ isLoading: true });

      const data = getPaymentIntentClientSecret({
        paidAmount: parseFloat(sumPriceInSource(cartList, photoPrices)) - (couponCode ? couponCode!.discountAmount : 0),
        totalAmount: parseFloat(sumPriceInSource(cartList, photoPrices)),
        discountCode: couponCode && couponCode!.code !== '' ? couponCode!.code : '',
        photos: cartList.map(cart => cart.id!),
        platform: 'web'
      });

      data.then(
        async response => {
          if (!response.data.success) {
            setState({ isLoading: false });

            setConfirmation({
              header: 'Payment',
              body: response.data.message,
              buttonText: 'OK',
              showing: true,
              callback: () => setConfirmation({ ...state, showing: false })
            });
            return;
          }

          const result = await stripe.confirmCardPayment(response.data.data as string, {
            payment_method: {
              card: elements.getElement(CardElement)!
            }
          });

          if (result.error) {
            setState({ isLoading: false });
            setConfirmation({
              header: 'Payment',
              body: result.error.message,
              buttonText: 'OK',
              showing: true,
              callback: () => setConfirmation({ ...state, showing: false })
            });
          } else {
            // The payment has been processed!
            if (result?.paymentIntent?.status === 'succeeded') {
              setTimeout(() => {
                setState({ isLoading: false });

                cartList.forEach(photo => {
                  removeImageFromCartAction(photo.id!);
                });

                setConfirmation({
                  header: 'Order complete!',
                  body: 'Your new TravelPix are now available to download as you like!',
                  buttonText: 'Take a look',
                  showing: true,
                  callback: goToGalleryPage
                });
              }, 5000);
            }
          }
        },
        error => {
          setState({ isLoading: false });
          setConfirmation({
            header: 'Payment',
            body: error.stack,
            buttonText: 'OK',
            showing: true,
            callback: () => setConfirmation({ ...confirmation, showing: false })
          });
        }
      );
    };
  }

  return (
    <Layout isCheckoutPage>
      <CheckoutPageStyle>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Check out</title>
        </Helmet>
        <div
          css={css`
          position: fixed;
          top: 0;
          left: 0;
          z-index: 12;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
          display: ${confirmation.showing ? 'flex' : 'none'};
          justify-content: center;
          align-items: center;
          .spinner {
            transform: translateY(-4em);
          }
        `}>
          <ConfirmModal
            header={confirmation.header}
            body={confirmation.body}
            buttonText={confirmation.buttonText}
            callback={confirmation.callback}
            css={css`
            .header {
              .title {
                padding: 0 50px;
              }
            }

            @media only screen and (max-width: 320px) {
              .description {
                padding: 0 16px;
              }
            }
          `} />
        </div>
        <div
          css={css`
          position: fixed;
          top: 0;
          left: 0;
          z-index: 12;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.3);
          display: ${state.isLoading ? 'flex' : 'none'};
          justify-content: center;
          align-items: center;
          .spinner {
            transform: translateY(-4em);
          }
      `}
        >
          <div className="spinner">
            <FadeLoader color="#fff" />
          </div>
        </div>
        <div className="main__container">
          <div className="form--coupon form--control">
            <div className="header__form">
              <p>Order Summary</p>
            </div>
            <div className="body__form">
              <div className="group__input">
                <div className="input-control">
                  <InputCheckout
                    placeHolder="Discount code"
                    className="input--control"
                    onChangeValue={onChangeCoupon}
                    value={couponCode ? couponCode.code : ''} />
                  <Button
                    css={css`
                    display: ${!isApplyCoupon ? 'none' : 'block'}
                  `}
                    className="btn-remove"
                    label="Remove"
                    onClick={onRemoveCoupon} />
                </div>
                <Button
                  onClick={onApplyCoupon}
                  css={css`
                    display: ${isApplyCoupon ? 'none' : 'block'}
                  `}
                  label={'Apply'}
                  className={'btn btn--control'}
                />
              </div>
              <div className="group__text">
                <div className="left__side">
                  <p>ITEMS</p>
                  <p>SUBTOTAL</p>
                  <p>COUPON APPLIED</p>
                  <p>TOTAL AMOUNT</p>
                </div>
                <div className="right__side">
                  <p>{cartList.length} photo{cartList.length > 1 ? 's' : ''}</p>
                  <p>£{sumPriceInSource(cartList, photoPrices)}</p>
                  <p className="text--coupon">-£{couponCode ? couponCode.discountAmount.toFixed(2) : '0.00'}</p>
                  <p className="text--amount">£{(parseFloat(sumPriceInSource(cartList, photoPrices)) - (couponCode ? couponCode.discountAmount : 0)).toFixed(2)}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="form--payment form--control">
            <div className="header__form ">
              <p>Payment</p>
              <img src="/visa_master_card/visa_master_card.png" alt="visa_master_card.png" />
            </div>
            <div className="body__form">
              <form className="group__input" onSubmit={handleSubmit}>
                <InputCheckout
                  placeHolder="Card holder name"
                  className="input--control"
                  onChangeValue={onChangeCardHoldername}
                  value={cardHolderName} />
                <InputCheckout
                  placeHolder="email@example.com"
                  className="input--control margin"
                  onChangeValue={onChangeEmail}
                  value={email} />
                <CardSection onChange={onChangeCard} />
                <Button label="Place order" className="btn btn--control btn--order" type="submit" />
              </form>
            </div>
          </div>
        </div>
      </CheckoutPageStyle>
    </Layout>
  );
};

const mapStateToProps = (state: RootState) => ({
  refs: state.galleryReducer.refs,
  cartList: state.galleryReducer.cartList,
  coupon: state.galleryReducer.coupon,
  photoPrices: state.galleryReducer.photoPrices
});

const mapDispatchToProps = {
  getCouponAction: getCoupon,
  saveCouponAction: saveCoupon,
  removeImageFromCartAction: removeImageFromCart
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
