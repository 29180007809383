import styled from '@emotion/styled';

const CardElementStyle = styled.div`
  .StripeElement {
    position: relative;
    width: 100%;
    height: 46px;
    margin-top: 16px;
    border-radius: 4px;
    border: 1px solid #cfd1d6;
    padding: 14px 20px 10px 20px;
    background: #fff;
  }

  .StripeElement--invalid {
    border-color: #fa755a;
  }

  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
`;

export { CardElementStyle };
